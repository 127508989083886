import React from "react";
import { CogIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import {
  DEPLOY_ENVIRONMENT,
  MARKETING_CHANNELS_VIEW_VAR,
} from "constants/constants";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import CreativeDashboardIcon from "./icons/creative-dashboard";
import CustomerJourneyIcon from "./icons/customer-journeys";
import MarketingOverviewIcon from "./icons/marketing-overview";
import MarketingChannelsIcon from "./icons/marketing-channels";
import MetaChannelIcon from "./icons/meta-channel";
import TikTokChannelIcon from "./icons/tiktok-channel";
import GoogleChannelIcon from "./icons/google-channel";
import useSelectedStores from "@lib/hooks/use-selected-stores";
import SidebarStoreSelector from "@components/Stores/StoreSelectorMenu/SidebarStoreSelector";
import LinkWithPersistentQuery from "@components/LinkWithQuery/LinkWithQuery";
import Image from "next/image";
import { useRouter } from "next/router";
import { classNames } from "@lib/util-functions";
import InfluencerIcon from "./icons/influencer-icon";
import CooperationsIcon from "./icons/cooperations-icon";
import InfluencerIconSmall from "./icons/influencer-icon-small";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { useReadLocalStorage } from "usehooks-ts";
import PinterestChannelIcon from "./icons/pinterest-channel";
import InfluencerOverviewIcon from "./icons/influencer-overview-icon";
import KnowledgeBase from "./icons/knowledge-base-icon";
import { useLogo } from "@lib/utils";
import ProfitLossIcon from "./icons/profit-loss-icon";
import {
  lastVisitedPageAtom,
  posthogFeatureFlagsAtom,
  sidebarDisclosureStateAtom,
} from "atoms";
import { useAtom } from "jotai";
import { usePostHog } from "posthog-js/react";

type NavigationType = {
  name: string;
  icon: any;
  href?: string;
  disabled: boolean;
  label?: string;
  color?: string;
  defaultOpen?: boolean;
  matchingHrefs?: string[];
  catchAll?: string;
  children?: NavigationType[];
};

const navigation: NavigationType[] = [
  {
    name: "Marketing Overview",
    icon: MarketingOverviewIcon,
    href: "/",
    disabled: false,
    label: "",
  },

  {
    name: "Marketing Channels",
    icon: MarketingChannelsIcon,
    disabled: false,
    defaultOpen: true,
    children: [
      {
        name: "Meta Ads",
        icon: MetaChannelIcon,
        href: "/channels/meta/dashboard",
        disabled: false,
        matchingHrefs: [
          "/channels/meta/dashboard",
          "/channels/meta/ads-manager",
        ],
        label: "",
      },
      {
        name: "Google Ads",
        icon: GoogleChannelIcon,
        href: "/channels/google/dashboard",
        disabled: false,
        matchingHrefs: [
          "/channels/google/dashboard",
          "/channels/google/ads-manager",
        ],
        label: "",
      },
      {
        name: "TikTok Ads",
        icon: TikTokChannelIcon,
        href: "/channels/tiktok/dashboard",
        disabled: false,
        matchingHrefs: [
          "/channels/tiktok/dashboard",
          "/channels/tiktok/ads-manager",
        ],
        label: "",
      },

      {
        name: "Pinterest Ads",
        icon: PinterestChannelIcon,
        href: "/channels/pinterest/dashboard",
        disabled: false,
        matchingHrefs: [
          "/channels/pinterest/dashboard",
          "/channels/pinterest/ads-manager",
        ],
        label: "",
        color: "bg-[#ffd85c] text-background-soft",
      },
    ],
  },
  {
    name: "Realtime Dashboard",
    icon: CreativeDashboardIcon,
    href: "/realtime",
    disabled: false,
  },
  {
    name: "Influencers",
    icon: InfluencerIcon,
    disabled: false,
    defaultOpen: true,
    children: [
      {
        name: "Overview",
        icon: InfluencerOverviewIcon,
        href: "/influencers/overview",
        disabled: false,
      },
      {
        name: "Influencer",
        icon: InfluencerIconSmall,
        href: "/influencers",
        catchAll: "/influencers/*",
        disabled: false,
      },

      {
        name: "Cooperations",
        icon: CooperationsIcon,
        href: "/influencers/cooperations",
        disabled: false,
      },
    ],
  },
  {
    name: "Creative Dashboard",
    icon: CreativeDashboardIcon,
    href: "/creatives/comparison",
    matchingHrefs: ["/creatives/comparison", "/creatives/analysis"],
    label:
      DEPLOY_ENVIRONMENT !== "beta" && DEPLOY_ENVIRONMENT !== "demo"
        ? "Beta"
        : undefined,
    color:
      DEPLOY_ENVIRONMENT !== "beta" && DEPLOY_ENVIRONMENT !== "demo"
        ? "bg-[#ffd85c] text-background-soft"
        : undefined,
    disabled: false,
  },
  {
    name: "Customer Journeys",
    icon: CustomerJourneyIcon,
    href: "/customer-journeys",
    disabled: false,
  },
];

const allHrefs: string[] = [];

for (const item of navigation) {
  item.href && allHrefs.push(item.href);
  if (item.matchingHrefs) allHrefs.push(...item?.matchingHrefs);
  if (item.children) {
    for (const subitem of item.children) {
      subitem.href && allHrefs.push(subitem.href);
      if (subitem.matchingHrefs) allHrefs.push(...subitem?.matchingHrefs);
    }
  }
}

const activeClassNames = "bg-primary-dark text-on-primary";
const normalClassNames =
  "text-foreground hover:text-on-primary hover:bg-primary-dark group-hover:text-on-primary transition-colors";
const disabledClassNames =
  "bg-gray-200 dark:bg-gray-700 text-gray-500 cursor-not-allowed";

const Sidebar = () => {
  const [lastVisitedPage, setLastVisitedPage] = useAtom(lastVisitedPageAtom);
  const [sidebarDisclosureState, setSidebarDisclosureState] = useAtom(
    sidebarDisclosureStateAtom
  );

  const { onStoreChanged, selectedStoreIds, selectedStores } =
    useSelectedStores();
  const logo = useLogo();
  const router = useRouter();
  const posthog = usePostHog();
  const [posthogFeatureFlags] = useAtom(posthogFeatureFlagsAtom);
  const isPnlEnabled = posthogFeatureFlags?.pnl_dashboard?.pnl;

  const isShopifySystem =
    selectedStores[0]?.shop_system?.toLowerCase().includes("shopify") &&
    isPnlEnabled;

  const navigationItems = isShopifySystem
    ? [
        ...navigation,
        {
          name: "Profit & Loss",
          icon: ProfitLossIcon,
          href: "/profit-loss-overview",
          disabled: false,
        },
      ]
    : navigation;

  const marketingChannelsView =
    (useReadLocalStorage(MARKETING_CHANNELS_VIEW_VAR) as string) ?? "dashboard";

  return (
    <div className="flex flex-col flex-grow py-6 bg-gray-200 dark:bg-gray-700 overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <Image width={166.4} height={28} alt="logo" src={logo} priority />
      </div>

      <div className="mt-4 flex-grow flex flex-col ">
        <div className="px-2 mt-4">
          <SidebarStoreSelector
            storeOptions={selectedStoreIds}
            onStoreChanged={onStoreChanged}
          />
        </div>
        <nav
          className="flex-1 mt-6 px-2 space-y-8 bg-gray-200 dark:bg-gray-700 flex flex-col justify-between"
          aria-label="Sidebar"
        >
          <div className="space-y-1">
            {navigationItems.map((item) => {
              const catchAllActive =
                item.catchAll && !allHrefs.includes(router.pathname)
                  ? new RegExp(item.catchAll).test(router.pathname)
                  : false;
              const current =
                catchAllActive || (item.href && router.pathname === item.href);
              const childrenActive = item.children
                ?.map(
                  (el) =>
                    el.href === router.pathname ||
                    el.matchingHrefs?.includes(router.pathname)
                )
                .reduce((prev, curr) => prev || curr);

              const className = classNames(
                item.disabled
                  ? disabledClassNames
                  : current
                    ? activeClassNames
                    : normalClassNames,
                "group flex items-baseline py-3 pl-3 pr-1 text-sm font-medium rounded-md"
              );

              const content = (
                <>
                  <span
                    className={classNames(
                      current
                        ? "fill-on-primary"
                        : "fill-foreground text-foreground group-hover:text-on-primary group-hover:fill-on-primary",
                      "mr-3 flex-shrink-0 h-4 w-4 transition-colors"
                    )}
                    aria-hidden="true"
                  >
                    <item.icon className="fill-inherit text-inherit" />
                  </span>
                  {item.name}
                </>
              );
              if (item.disabled && !item.children) {
                return (
                  <Tooltip key={item.name}>
                    <TooltipTrigger>
                      <div className={className}>
                        {content}{" "}
                        {item.label && (
                          <p
                            className={`text-xs ml-2 rounded-md py-0.5 px-1 ${
                              item.color
                                ? item.color
                                : "text-foreground bg-gray-600"
                            } inline`}
                          >
                            {item.label}
                          </p>
                        )}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="w-80">
                        Dashboard not published, yet. As soon as it is released
                        you can access it here.
                      </div>
                    </TooltipContent>
                  </Tooltip>
                );
              }
              return !item.children ? (
                <LinkWithPersistentQuery
                  passHref
                  key={item.name}
                  href={item.disabled ? "#" : item.href ?? ""}
                  className={className}
                >
                  <>
                    {content}{" "}
                    {item.label && (
                      <p
                        className={`text-xs ml-2 rounded-md py-0.5 px-1 ${
                          item.color
                            ? item.color
                            : "text-foreground bg-gray-600"
                        } inline`}
                      >
                        {item.label}
                      </p>
                    )}
                  </>
                </LinkWithPersistentQuery>
              ) : (
                <Disclosure
                  as="div"
                  key={item.name}
                  defaultOpen={
                    sidebarDisclosureState[item.name] !== undefined
                      ? sidebarDisclosureState[item.name]
                      : item.defaultOpen || childrenActive
                  }
                  className="space-y-1"
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        onClick={() => {
                          setSidebarDisclosureState({
                            ...sidebarDisclosureState,
                            [item.name]: !open,
                          });
                        }}
                        className={classNames(
                          item.disabled
                            ? disabledClassNames
                            : current
                              ? activeClassNames
                              : normalClassNames,
                          "group w-full flex items-baseline pl-3 pr-1 py-3 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                        )}
                      >
                        <span
                          className={classNames(
                            current
                              ? "fill-on-primary"
                              : "fill-foreground text-foreground group-hover:text-on-primary group-hover:fill-on-primary",
                            "mr-3 flex-shrink-0 h-4 w-4 transition-colors"
                          )}
                          aria-hidden="true"
                        >
                          <item.icon className="fill-inherit" />
                        </span>
                        <span className="flex-1">{item.name}</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-gray-400 rotate-180" : "text-gray-300",
                            "ml-3 flex-shrink-0 h-4 w-4 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                          )}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="space-y-1">
                        {item.children?.map((subItem) => {
                          const subCatchAllActive =
                            subItem.catchAll &&
                            !allHrefs.includes(router.pathname)
                              ? new RegExp(subItem.catchAll).test(
                                  router.pathname
                                )
                              : false;
                          const currentSubActive =
                            !subItem.disabled &&
                            (subCatchAllActive ||
                              (subItem.href &&
                                (router.pathname === subItem.href ||
                                  subItem.matchingHrefs?.includes(
                                    router.pathname
                                  ))));
                          let href = subItem.href;
                          if (item.name === "Marketing Channels") {
                            href =
                              subItem.matchingHrefs?.find((el) =>
                                el.includes(`${marketingChannelsView}`)
                              ) ?? subItem.href;
                          }
                          const subClassName = `group w-full flex items-center pl-11 pr-3 py-3 text-sm font-medium  rounded-md ${
                            subItem.disabled
                              ? disabledClassNames
                              : currentSubActive
                                ? activeClassNames
                                : normalClassNames
                          }`;

                          const content = (
                            <span className="flex items-center ">
                              <span
                                className={classNames(
                                  currentSubActive
                                    ? "fill-on-primary"
                                    : "fill-foreground text-foreground group-hover:text-on-primary group-hover:fill-on-primary",
                                  "mr-3 flex-shrink-0 h-4 w-4 transition-colors"
                                )}
                                aria-hidden="true"
                              >
                                <subItem.icon className="fill-inherit" />
                              </span>
                              {subItem.name}
                            </span>
                          );

                          if (subItem.disabled) {
                            return (
                              <div className={subClassName} key={subItem.name}>
                                {content}{" "}
                                {subItem.label && (
                                  <p className="text-foreground text-sm ml-2 rounded-md py-0.5 px-1 bg-gray-600 inline">
                                    {subItem.label}
                                  </p>
                                )}
                              </div>
                            );
                          }
                          return (
                            <LinkWithPersistentQuery
                              href={href ?? subItem.href ?? ""}
                              key={subItem.name}
                              legacyBehavior
                              passHref
                            >
                              <Disclosure.Button
                                className={subClassName}
                                as="a"
                              >
                                {content}
                                {subItem.label && (
                                  <p
                                    className={`text-xs ml-2 rounded-md py-0.5 px-1 ${
                                      subItem.color
                                        ? subItem.color
                                        : "text-foreground bg-gray-600"
                                    } inline`}
                                  >
                                    {subItem.label}
                                  </p>
                                )}
                              </Disclosure.Button>
                            </LinkWithPersistentQuery>
                          );
                        })}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            })}
          </div>

          <div>
            <a
              className="text-foreground hover:text-on-primary hover:bg-primary-dark group-hover:text-on-primary transition-colors group flex items-center px-3 py-3 text-sm font-medium rounded-md"
              href={"https://calendly.com/marc-tracify/consultation"}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                posthog?.capture("Consultation link clicked");
              }}
            >
              <span
                className={
                  "group-hover:text-background fill-gray-200 group-hover:fill-gray-100 dark:group-hover:text-gray-100 dark:group-hover:fill-gray-200 flex-shrink-0 mr-1.5"
                }
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.875 10.6332C1.875 11.9665 2.81083 13.1282 4.13083 13.3224C5.03667 13.4557 5.95167 13.5582 6.875 13.6299V17.4999L10.2717 14.1032C10.502 13.8743 10.8112 13.7423 11.1358 13.734C12.7203 13.6902 14.3009 13.5527 15.8692 13.3224C17.1892 13.1282 18.125 11.9674 18.125 10.6324V5.61738C18.125 4.28238 17.1892 3.12155 15.8692 2.92738C13.9258 2.64214 11.9642 2.49926 10 2.49988C8.00667 2.49988 6.04667 2.64571 4.13083 2.92738C2.81083 3.12155 1.875 4.28321 1.875 5.61738V10.6324V10.6332Z"
                    stroke="currentColor"
                    stroke-width="0.7"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.91667 5C9.81998 5 9.72723 5.03615 9.65887 5.1005C9.59051 5.16485 9.55208 5.25213 9.55208 5.34314V10.4902C9.55208 10.5812 9.59051 10.6685 9.65887 10.7328C9.72723 10.7972 9.81998 10.8333 9.91667 10.8333C10.0134 10.8333 10.1061 10.7972 10.1745 10.7328C10.2428 10.6685 10.2813 10.5812 10.2813 10.4902V5.34314C10.2813 5.25213 10.2428 5.16485 10.1745 5.1005C10.1061 5.03615 10.0134 5 9.91667 5ZM12.4688 7.7451C12.3721 7.7451 12.2793 7.78126 12.211 7.8456C12.1426 7.90994 12.1042 7.99724 12.1042 8.08824V10.4902C12.1042 10.5812 12.1426 10.6685 12.211 10.7328C12.2793 10.7972 12.3721 10.8333 12.4688 10.8333C12.5654 10.8333 12.6582 10.7972 12.7265 10.7328C12.7949 10.6685 12.8333 10.5812 12.8333 10.4902V8.08824C12.8333 7.99724 12.7949 7.90994 12.7265 7.8456C12.6582 7.78126 12.5654 7.7451 12.4688 7.7451ZM7.36458 6.37255C7.26789 6.37255 7.17516 6.4087 7.10678 6.47305C7.03841 6.5374 7 6.62468 7 6.71569V10.4902C7 10.5812 7.03841 10.6685 7.10678 10.7328C7.17516 10.7972 7.26789 10.8333 7.36458 10.8333C7.46128 10.8333 7.55401 10.7972 7.62238 10.7328C7.69075 10.6685 7.72917 10.5812 7.72917 10.4902V6.71569C7.72917 6.62468 7.69075 6.5374 7.62238 6.47305C7.55401 6.4087 7.46128 6.37255 7.36458 6.37255Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              Book a Consultation
            </a>
            <a
              className="text-foreground hover:text-on-primary hover:bg-primary-dark group-hover:text-on-primary transition-colors group flex items-center px-3 py-3 text-sm font-medium rounded-md"
              href={"https://tracify.learningsuite.io/"}
              target="_blank"
              rel="noreferrer"
            >
              <span
                className={
                  "fill-foreground text-foreground group-hover:text-on-primary group-hover:fill-on-primary mr-3 flex-shrink-0 h-4 w-4"
                }
                aria-hidden="true"
              >
                <KnowledgeBase />
              </span>
              Academy
            </a>
            <LinkWithPersistentQuery
              passHref
              href={"/settings"}
              className={classNames(
                router.pathname === "/settings"
                  ? activeClassNames
                  : normalClassNames,
                "group flex items-center px-3 py-3 text-sm font-medium rounded-md mt-1"
              )}
              onClick={() => {
                setLastVisitedPage(router.asPath?.split("?")[0]);
              }}
            >
              <>
                <span
                  className={classNames(
                    router.pathname === "/settings"
                      ? "fill-on-primary"
                      : "fill-foreground text-foreground group-hover:text-on-primary group-hover:fill-on-primary",
                    "mr-3 flex-shrink-0 h-4 w-4 transition-colors"
                  )}
                  aria-hidden="true"
                >
                  <CogIcon className={`h-5 w-5`} />
                </span>
                Settings
              </>
            </LinkWithPersistentQuery>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
